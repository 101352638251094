import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { apiUrl, titlePrefix } from "../common";
import CodeViewer from "../components/CodeViewer";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Validation() {
  const [validations, setValidations] = useState([]);
  const { id, repo } = useParams();
  const [selectedTest] = useState(id);
  const [selectedRepo] = useState(repo);
  const [loading, setLoading] = useState(false);

  const getValidation = () => {
    setLoading(true);
    axios
      .get(apiUrl + `/validation/${selectedTest}/${selectedRepo}`)
      .then((x) => {
        setValidations(
          x.data.map((y) => [
            y.filename,
            y.fileExists,
            y.missingLines,
            y.extraLines,
            y.firstMissingLines,
            y.firstExtraLines,
          ]),
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = `${titlePrefix} - Validation ${selectedTest} ${selectedRepo}`;
    getValidation();
  }, []);

  return (
    <div>
      <div className="mb-10">
        <div className="text-xl">
          <Link to={`/test/${selectedTest}`} className="underline ">
            Test {selectedTest}
          </Link>
          <div className="inline">{" > "}</div>
          <div className="inline">
            Validations of {selectedRepo} from Test {selectedTest}
          </div>
          <LoadingSpinner active={loading} />
        </div>
      </div>
      {validations.map((x) => (
        <div key={x[0]} className="mb-10">
          <div className="text-xl mb-2 font-semibold">{x[0]}</div>
          <div className="text-md">
            File exists: {x[1] == true ? "yes" : "no"}
          </div>
          <div className="text-md">Missing lines: {x[2]}</div>
          <div className="text-md">Extra lines: {x[3]}</div>
          {x[4].length > 0 ? (
            <>
              <div className="text-md mt-2">First missing lines</div>
              <CodeViewer>{x[4]}</CodeViewer>
            </>
          ) : (
            ""
          )}
          {x[5].length > 0 ? (
            <>
              <div className="text-md mt-2">First extra lines</div>
              <CodeViewer>{x[5]}</CodeViewer>
            </>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
}
