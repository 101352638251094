import React, { Component } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Input from "./pages/Input";
import Log from "./pages/Log";
import Diff from "./pages/Diff";
import Container from "./components/Container";
import Header from "./components/Header";
import Faq from "./pages/Faq";
import Validation from "./pages/Validation";
import Groups from "./pages/Groups";
import GroupsPending from "./pages/GroupsPending";
import Group from "./pages/Group";
import GroupRegister from "./pages/GroupRegister";
import GroupInvite from "./pages/GroupInvite";

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
              <Container>
                <Header />
                <Routes>
                  <Route path="/test" element={<Home />} />
                  <Route path="/test/:id" element={<Home />} />
                  <Route path="/test/:id/input" element={<Input />} />
                  <Route path="/test/:id/:repo" element={<Log />} />
                  <Route path="/test/:id/:repo/:query" element={<Diff />} />
                  <Route
                    path="/test/:id/:repo/validation"
                    element={<Validation />}
                  />
                  <Route path="/group" element={<Groups />} />
                  <Route path="/group/:id/" element={<Group />} />
                  <Route path="/group/:id/:message" element={<Group />} />
                  <Route path="/group/pending" element={<GroupsPending />} />
                  <Route path="/group/register" element={<GroupRegister />} />
                  <Route
                    path="/group/invite"
                    element={<Navigate to="/group" />}
                  />
                  <Route
                    path="/group/invite/:secret"
                    element={<GroupInvite />}
                  />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/*" element={<Navigate to="/test" />} />
                </Routes>
              </Container>
            }
          />
        </Routes>
      </BrowserRouter>
    );
  }
}
