import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faXmark,
  faFile,
  faHourglassEnd,
  faMemory,
} from "@fortawesome/free-solid-svg-icons";
import { nQueries, sumList } from "../common";
import { Link } from "react-router-dom";

function queryStyle(x, i) {
  if (x.correctQueries[i] === 0 && x.wrongQueries[i] === 0) {
    return "bg-green-200 dark:brightness-85";
  } else if (x.wrongQueries[i] === 0) {
    return "bg-green-200 dark:brightness-85";
  } else if (x.correctQueries[i] === 0 && x.nDiffs[i] > 0) {
    return "bg-red-200 underline dark:brightness-85";
  } else if (x.correctQueries[i] === 0) {
    return "bg-neutral-200 dark:brightness-85";
  } else {
    return (
      "bg-orange-200 dark:brightness-85 " + (x.nDiffs[i] > 0 ? "underline" : "")
    );
  }
}

export default function TestViewer(props) {
  return (
    <>
      <table className="table-auto border">
        <thead className="bg-gray-100 dark:bg-darkmode2">
          <tr>
            <th className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-5 lg:py-3 lg:px-1 xl:px-2.5">
              Repo
            </th>
            <th className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-1 lg:py-3 lg:px-1 xl:px-2.5">
              Log
            </th>
            <th
              className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-1 lg:py-3 lg:px-1 xl:px-2.5"
              title="Whether the PDF reports exist at trabalho-pratico/relatorio-fase1.pdf and trabalho-pratico/relatorio-fase2.pdf"
            >
              PDF
            </th>
            <th
              className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-1 lg:py-3 lg:px-1 xl:px-2.5"
              title="Whether the programa-testes exists after the make at trabalho-pratico/programa-testes"
            >
              Test
            </th>
            <th className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-1 lg:py-3 lg:px-1 xl:px-2.5">
              Make
            </th>
            <th className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-1 lg:py-3 lg:px-1 xl:px-2.5">
              Exec
            </th>
            {[...Array(nQueries).keys()].map((i) => (
              <th
                key={i}
                className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-1 md:px-1.5 lg:py-3 lg:px-2 xl:px-2.5 2xl:px-3"
              >{`Q${i + 1}`}</th>
            ))}
            <th className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-1 lg:py-3 lg:px-1 xl:px-2.5 2xl:px-3">
              Total
            </th>
            <th
              className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-1 lg:py-3 lg:px-1 xl:px-2.5 2xl:px-3"
              title="Validations"
            >
              Val.
            </th>
            <th className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-1 lg:py-3 lg:px-1 xl:px-2.5 2xl:px-3">
              Leaks MB<a href="#leaks-note">*</a>
            </th>
            <th className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-1 lg:py-3 lg:px-1 xl:px-2.5 2xl:px-3">
              Time S
            </th>
            <th className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-1 lg:py-3 lg:px-1 xl:px-2.5 2xl:px-3">
              Mem MB
            </th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((x) => (
            <tr key={x.repoId}>
              <td className="text-center py-1 border-2 md:border-4 border-white dark:border-darkmode">
                {x.repoId}
              </td>
              <td className="text-center py-1 border-2 md:border-4 border-white dark:border-darkmode">
                <Link to={`/test/${x.testId}/${x.repoId}`}>
                  <FontAwesomeIcon icon={faFile} />
                </Link>
              </td>
              <td
                className={
                  "text-center py-1 dark:text-darkmode border-2 md:border-4 border-white dark:border-darkmode rounded dark:brightness-85 " +
                  (x.report && x.report2
                    ? "bg-green-200"
                    : x.report || x.report2
                      ? "bg-orange-200"
                      : "bg-red-200")
                }
              >
                {x.report && x.report2
                  ? "2/2"
                  : x.report || x.report2
                    ? "1/2"
                    : "0/2"}
              </td>
              <td
                className={
                  "text-center py-1 dark:text-darkmode border-2 md:border-4 border-white dark:border-darkmode rounded dark:brightness-85 " +
                  (x.test ? "bg-green-200" : "bg-red-200")
                }
              >
                {x.test ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  <FontAwesomeIcon icon={faXmark} />
                )}
              </td>
              <td
                className={
                  "text-center py-1 dark:text-darkmode border-2 md:border-4 border-white dark:border-darkmode rounded dark:brightness-85 " +
                  (x.compiled ? "bg-green-200" : "bg-red-200")
                }
              >
                {x.compiled ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  <FontAwesomeIcon icon={faXmark} />
                )}
              </td>
              <td
                className={
                  "text-center py-1 dark:text-darkmode border-2 md:border-4 border-white dark:border-darkmode rounded dark:brightness-85 " +
                  (x.executed && !x.timeout && !x.outOfMemory
                    ? "bg-green-200"
                    : "bg-red-200")
                }
              >
                {x.executed && !x.timeout && !x.outOfMemory ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : !x.timeout && !x.outOfMemory ? (
                  <FontAwesomeIcon icon={faXmark} />
                ) : x.timeout ? (
                  <FontAwesomeIcon
                    icon={faHourglassEnd}
                    title="Time limit exceeded"
                  />
                ) : (
                  <FontAwesomeIcon icon={faMemory} title="Memory exceeded" />
                )}
              </td>
              {[...Array(x.correctQueries.length).keys()].map((i) => (
                <td
                  key={i}
                  className={
                    "text-center py-1 dark:text-darkmode border-2 md:border-4 border-white dark:border-darkmode rounded " +
                    queryStyle(x, i)
                  }
                >
                  {x.wrongQueries[i] === 0 || x.nDiffs[i] === 0 ? (
                    <div>
                      {x.correctQueries[i] + x.wrongQueries[i] !== 0
                        ? Math.round(
                            (x.correctQueries[i] /
                              (x.correctQueries[i] + x.wrongQueries[i])) *
                              100,
                          )
                        : 100}
                      %
                    </div>
                  ) : (
                    <Link to={`/test/${x.testId}/${x.repoId}/${i + 1}`}>
                      {Math.round(
                        (x.correctQueries[i] /
                          (x.correctQueries[i] + x.wrongQueries[i])) *
                          100,
                      )}
                      %
                    </Link>
                  )}
                </td>
              ))}
              <td
                className={
                  "text-center py-1 dark:text-darkmode border-2 md:border-4 border-white dark:border-darkmode dark:brightness-85 rounded " +
                  (sumList(x.wrongQueries) === 0
                    ? "bg-green-200"
                    : sumList(x.correctQueries) === 0
                      ? "bg-red-200"
                      : "bg-orange-200")
                }
              >
                {Math.round(
                  (sumList(x.correctQueries) /
                    (sumList(x.correctQueries) + sumList(x.wrongQueries))) *
                    100,
                )}
                %
              </td>
              <td
                className={
                  "text-center py-1 dark:text-darkmode border-2 md:border-4 border-white dark:border-darkmode dark:brightness-85 rounded " +
                  (x.validationScore === 100
                    ? "bg-green-200"
                    : x.validationScore === 0
                      ? "bg-red-200"
                      : "bg-orange-200")
                }
              >
                {x.validationScore === 100 ? (
                  <div>{x.validationScore}%</div>
                ) : (
                  <Link
                    className="underline"
                    to={`/test/${x.testId}/${x.repoId}/validation`}
                  >
                    {x.validationScore + "%"}
                  </Link>
                )}
              </td>
              <td
                className={
                  "text-center py-1 dark:text-darkmode border-2 md:border-4 border-white dark:border-darkmode dark:brightness-85 rounded " +
                  (x.memoryLeaksMb === null
                    ? "bg-gray-200"
                    : x.ignoreLeaks
                      ? "bg-green-200"
                      : "bg-red-200")
                }
              >
                {x.memoryLeaksMb !== null ? x.memoryLeaksMb : "-"}
              </td>
              <td
                className={
                  "text-center py-1 dark:text-darkmode border-2 md:border-4 border-white dark:border-darkmode dark:brightness-85 rounded bg-blue-200"
                }
              >
                {x.durationS !== null ? x.durationS : "-"}
              </td>
              <td
                className={
                  "text-center py-1 dark:text-darkmode border-2 md:border-4 border-white dark:border-darkmode dark:brightness-85 rounded bg-blue-200"
                }
              >
                {x.maxMemoryMb !== null ? Math.round(x.maxMemoryMb) : "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div id="leaks-note">*Leaks tested with the regular clean dataset.</div>
    </>
  );
}
