import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHourglassEnd } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function GroupsViewer(props) {
  return (
    <>
      <table className="table-auto border">
        <thead className="bg-gray-100 dark:bg-darkmode2">
          <tr>
            <th className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-5 lg:py-3 lg:px-1 xl:px-2.5">
              Group
            </th>
            <th className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-5 lg:py-3 lg:px-1 xl:px-2.5">
              Email
            </th>
            <th className="text-center border-2 md:border-4 border-white dark:border-darkmode rounded py-1 px-1 lg:py-3 lg:px-1 xl:px-2.5">
              Invite Status
            </th>
          </tr>
        </thead>
        <tbody>
          {props.groups.map((x, index) => (
            <tr key={index}>
              <td className="text-center py-1 border-2 md:border-4 border-white dark:border-darkmode bg-gray-200 rounded dark:bg-gray-700 dark:text-white underline">
                <Link to={`/group/${x.groupId}`}>{x.groupId}</Link>
              </td>
              <td className="text-center py-1 border-2 md:border-4 border-white dark:border-darkmode bg-gray-200 rounded dark:bg-gray-700 dark:text-white">
                {x.email}
              </td>
              <td
                className={
                  "text-center py-1 dark:text-darkmode border-2 md:border-4 border-white dark:border-darkmode rounded dark:brightness-85 " +
                  (x.inviteStatus ? "bg-green-200" : "bg-orange-200")
                }
              >
                {x.inviteStatus ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  <FontAwesomeIcon icon={faHourglassEnd} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
