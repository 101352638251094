import React, { useEffect, useState } from "react";
import * as Diff2Html from "diff2html";
import "diff2html/bundles/css/diff2html.min.css";

export default function CodeViewer(props) {
  const [diff, setDiff] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    var diffHtml = Diff2Html.html(props.diff, {
      inputFormat: "json",
      matching: "lines",
      outputFormat: window.innerWidth > 800 ? "side-by-side" : "line-by-line",
      synchronisedScroll: true,
      highlight: true,
      renderNothingWhenEmpty: false,
      fileListToggle: false,
      fileListStartVisible: false,
      diffStyle: "char",
    });
    setDiff(diffHtml);
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading diff...</div>;
  } else {
    return <div dangerouslySetInnerHTML={{ __html: diff }}></div>;
  }
}
