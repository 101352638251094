import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { apiUrl, titlePrefix } from "../common";
import CodeViewer from "../components/CodeViewer";
import DiffViewer from "../components/DiffViewer";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Diff() {
  const [diffs, setDiffs] = useState([]);
  const { id, repo, query } = useParams();
  const [selectedTest] = useState(id);
  const [selectedRepo] = useState(repo);
  const [selectedQuery] = useState(query);
  const [loading, setLoading] = useState(false);

  const getDiff = () => {
    setLoading(true);
    axios
      .get(apiUrl + `/diff/${selectedTest}/${selectedRepo}/${selectedQuery}`)
      .then((x) => {
        setDiffs(x.data.map((y) => [y.commandId, y.commandStr, y.diff]));
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = `${titlePrefix} - Test ${selectedTest} ${selectedRepo} Q${selectedQuery}`;
    getDiff();
  }, []);

  return (
    <div>
      <div className="mb-10">
        <div className="text-xl">
          <Link to={`/test/${selectedTest}`} className="underline ">
            Test {selectedTest}
          </Link>
          <div className="inline">{" > "}</div>
          <div className="inline">
            Query Q{selectedQuery} diffs of {selectedRepo} from Test{" "}
            {selectedTest}
          </div>
          <LoadingSpinner active={loading} />
        </div>
      </div>
      {diffs.map((x) => (
        <div key={x[0]} className="mb-10">
          <div className="text-xl mb-2">Command {x[0]}</div>
          <CodeViewer>{x[1]}</CodeViewer>
          <DiffViewer diff={x[2]} />
        </div>
      ))}
    </div>
  );
}
