import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { apiUrl } from "../common";

export default function Header() {
  const [checked, setChecked] = useState(false);
  const [faqNotification, setFaqNotification] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

  const changeMode = (e) => {
    setChecked(!checked);
  };

  useEffect(() => {
    setCookie("dark-mode", checked ? "true" : "false", { path: "/" });
    const root = document.getElementsByTagName("html")[0];
    if (checked) {
      root.setAttribute("class", "dark");
    } else {
      root.classList.remove("dark");
    }
  }, [checked]);

  useEffect(() => {
    const dm = cookies["dark-mode"];
    if (dm === "true") {
      setChecked(true);
    } else {
      setChecked(false);
    }
    axios.get(apiUrl + "/var/faqNotification/").then((x) => {
      setFaqNotification(x.data);
    });
  }, []);

  return (
    <div className="mb-6">
      <div className="text-4xl font-extrabold">
        <Link to="/">LI3 Automatic Testing</Link>
      </div>
      <div>
         <div className="text-lg font-semibold inline-block underline mr-2">
           <Link to="/test">Tests</Link> 
         </div> 
         <div className="text-lg font-semibold inline-block mr-2">|</div> 
        <div className="text-lg font-semibold inline-block underline mr-2">
          <Link to="/group">Groups</Link>
        </div>
        <div className="text-lg font-semibold inline-block mr-2">|</div>
        <div className="text-lg font-semibold inline-block underline mr-2">
          <Link to="/group/pending">Pending groups</Link>
        </div>
        <div className="text-lg font-semibold inline-block mr-2">|</div>
        <div className="text-lg font-semibold inline-block underline mr-2">
          <Link to="/group/register">Create group</Link>
        </div>
        <div className="text-lg font-semibold inline-block mr-2">|</div>
        <div className="text-lg font-semibold inline-block underline mr-2">
          <Link to="/faq">FAQ</Link>
          {faqNotification ? (
            <div className="h-2 w-2 mb-2 bg-red-500 rounded inline-block"></div>
          ) : (
            ""
          )}
        </div>
        <div className="text-lg font-semibold inline-block mr-2">|</div>
        <div className="inline-block">
          <div className="inline text-lg font-semibold mr-2">Dark mode</div>
          <label className="inline-flex relative items-center cursor-pointer align-bottom">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              onChange={changeMode}
              checked={checked}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>
      </div>
      <div className="text-sm font-semibold inline-block mr-2 underline">
        <a href="https://github.com/nuno-faria">@nuno-faria</a>
      </div>
      <div className="text-sm font-semibold inline-block mr-2">/</div>
      <div className="text-sm font-semibold inline-block mr-2 underline">
        <a href="https://github.com/luismeruje">@luismeruje</a>
      </div>
      <div className="text-sm font-semibold inline-block mr-2">/</div>
      <div className="text-sm font-semibold inline-block mr-2 underline">
        <a href="https://github.com/ruicunham">@ruicunham</a>
      </div>
    </div>
  );
}
