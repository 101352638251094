import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { apiUrl, titlePrefix } from "../common";
import LoadingSpinner from "../components/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import GroupViewer from "../components/GroupViewer";

export default function GroupInvite() {
  const navigate = useNavigate();
  const { secret } = useParams();
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState([]);
  const [groupId, setGroupId] = useState();

  const getInviteInfo = () => {
    setLoading(true);
    axios.get(apiUrl + `/group/invite/${secret}`).then((x) => {
      if (!x.data.length) navigate("/");

      setGroupId(x.data[0].groupId);
      const newElements = Array.from(x.data, (e) => ({
        email: e.email,
        github: e.github,
        inviteStatus: e.inviteStatus,
      }));
      setElements(newElements);
      setLoading(false);
    });
  };

  const acceptInvite = () => {
    setLoading(true);
    axios.post(apiUrl + `/group/invite/${secret}/accept`).then((x) => {
      const newGroupId = x.data.groupId;
      navigate(`/group/${newGroupId}`);
    });
  };

  const rejectInvite = () => {
    setLoading(true);
    axios.post(apiUrl + `/group/invite/${secret}/reject`).then((_) => {
      navigate(`/`);
    });
  };

  useEffect(() => {
    document.title = `${titlePrefix} - Group Invite`;
    getInviteInfo();
  }, []);

  return (
    <div>
      <div className="mb-4">
        <b>Group {groupId} Invite</b>
        <LoadingSpinner active={loading} />
      </div>
      <div className="mb-6 overflow-x-auto">
        <GroupViewer elements={elements} />
      </div>
      <div>Do you wish to accept this Invite?</div>
      <div className="flex gap-20">
        <button
          className="text-black bg-green-200 hover:bg-green-400 focus:ring-4 focus:outline-none font-medium rounded text-base w-full sm:w-auto px-5 py-2.5 text-center dark:brightness-85"
          onClick={acceptInvite}
        >
          Accept
        </button>
        <button
          className="text-black bg-red-200 hover:bg-red-400 focus:ring-4 focus:outline-none font-medium rounded text-base w-full sm:w-auto px-5 py-2.5 text-center dark:brightness-85"
          onClick={rejectInvite}
        >
          Reject
        </button>
      </div>
    </div>
  );
}
