import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl, titlePrefix } from "../common";
import LoadingSpinner from "../components/LoadingSpinner";
import { useNavigate } from "react-router-dom";

export default function GroupRegister() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // NOTE: Currently we always assume groups with 3 elements
  const [elements, setElements] = useState([
    {
      email: { value: "", isValid: true },
      github: {
        value: "",
        isValid: true,
      },
    },
    {
      email: { value: "", isValid: true },
      github: {
        value: "",
        isValid: true,
      },
    },
    {
      email: { value: "", isValid: true },
      github: {
        value: "",
        isValid: true,
      },
    },
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    elements.map((x) => {
      x.email.isValid = true;
      x.github.isValid = true;
    });
    setErrorMessage("");
    axios.post(apiUrl + `/group/register`, elements).then((x) => {
      if (x.data.success) {
        navigate(`/group/${x.data.result}/${x.data.message}`);
      } else {
        setElements(x.data.result);
        setErrorMessage(x.data.error);
      }
      setLoading(false);
    });
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newElements = [...elements];
    newElements[index][name].value = value;
    setElements(newElements);
  };

  useEffect(() => {
    document.title = `${titlePrefix} - Create Group`;
  }, []);

  return (
    <div>
      <div className="mb-4">
        <b>Create Group</b>
        <LoadingSpinner active={loading} />
      </div>
      <form onSubmit={handleSubmit}>
        <table className="table-auto border-separate">
          <thead className="bg-gray-100 dark:bg-darkmode2">
            <tr>
              <th className="text-center py-1 px-5 lg:py-3 lg:px-1 xl:px-2.5">
                Email
              </th>
              <th className="text-center py-1 px-5 lg:py-3 lg:px-1 xl:px-2.5">
                GitHub Username
              </th>
            </tr>
          </thead>
          <tbody>
            {elements.map((member, index) => (
              <tr key={index}>
                <td>
                  <input
                    className={
                      "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 " +
                      (member.email.isValid
                        ? ""
                        : "border-red-500 dark:border-red-500 text-red-500 dark:text-red-500")
                    }
                    type="email"
                    name="email"
                    placeholder="numero@alunos.uminho.pt"
                    value={member.email.value}
                    onChange={(event) => handleChange(index, event)}
                    required
                  />
                </td>
                <td>
                  <input
                    className={
                      "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 " +
                      (member.github.isValid
                        ? ""
                        : "border-red-500 dark:border-red-500 text-red-500 dark:text-red-500")
                    }
                    type="text"
                    name="github"
                    placeholder="username"
                    value={member.github.value}
                    onChange={(event) => handleChange(index, event)}
                    required
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="text-red-500">
          {errorMessage.length > 0 ? errorMessage : ""}
        </div>
        <div className="flex gap-2">
          <button
            className="text-black bg-gray-200 hover:bg-green-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-base w-full sm:w-auto px-5 py-2.5 text-center dark:bg-darkmode2 dark:text-white dark:hover:bg-green-400 dark:hover:brightness-85 dark:hover:text-black"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
