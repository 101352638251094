import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { apiUrl, titlePrefix } from "../common";
import LoadingSpinner from "../components/LoadingSpinner";
import TestViewer from "../components/TestViewer";

export default function Home() {
  const [tests, setTests] = useState([]);
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [selectedTest, setSelectedTest] = useState(id);
  const [selectedTestStats, setSelectedTestStats] = useState(undefined);
  const navigate = useNavigate();

  const getTests = () => {
    axios.get(apiUrl + "/test").then((x) => {
      if (!x.data.length) return;
      setTests(x.data);
      const selected = id !== undefined ? id : x.data[0].testId;
      if (selected !== selectedTest) {
        //navigate(`/test/${selected}`);
	//console.log("Passed navigate \n");
        setSelectedTest(selected);
      }
    });
  };

  const getRuns = (testId) => {
    axios.get(apiUrl + "/run/" + testId).then((x) => {
      setRuns(x.data);
      setLoading(false);
    });
  };

  const getTestStats = (testId) => {
    axios.get(apiUrl + `/test/${testId}/stats`).then((x) => {
      setSelectedTestStats(x.data);
    });
  };

  const updateTest = (e) => {
    setLoading(true);
    navigate(`/test/${e.target.value}`);
    setSelectedTest(e.target.value);
  };

  useEffect(() => {
    getTests();
  }, []);

  useEffect(() => {
    if (selectedTest !== undefined) {
      document.title = `${titlePrefix} - Test ${id}`;
      getRuns(selectedTest);
      getTestStats(selectedTest);
    }
  }, [selectedTest]);

  return (
    <div>
      <div className="mb-4">
        <select
          className="text-lg rounded p-1 dark:bg-darkmode2"
          onChange={updateTest}
          value={selectedTest}
        >
          {tests.map((x) => (
            <option key={x["testId"]} value={x["testId"]}>
              {x["testId"]} - {x["date"].split("T")[0]}{" "}
              {x["hour"] !== undefined && x["hour"] !== "" && x["hour"] !== null
                ? `[${x["hour"]}]`
                : ""}
            </option>
          ))}
        </select>
        <div className="text-lg underline inline-block mx-4 hover:cursor-pointer">
          <Link to={`/test/${selectedTest}/input`}>Input used</Link>
        </div>
        <LoadingSpinner active={loading} />
        {selectedTestStats !== undefined && selectedTestStats.info !== null ? (
          <div>{selectedTestStats.info}</div>
        ) : (
          ""
        )}
      </div>
      <div className="mb-6 overflow-x-auto">
        <TestViewer data={runs} />
      </div>
      {selectedTestStats !== undefined ? (
        <div>
          <div className="font-semibold">Statistics</div>
          <div className="pl-2">
            <div>Total runs: {selectedTestStats.totalRuns}</div>
            <div>
              Completed make percentage:{" "}
              {Math.round(
                (selectedTestStats.totalSucceededCompiles /
                  selectedTestStats.totalRuns) *
                  100,
              )}
              %
            </div>
            <div>
              Completed execution percentage:{" "}
              {Math.round(
                (selectedTestStats.totalSucceededExecs /
                  selectedTestStats.totalRuns) *
                  100,
              )}
              %
            </div>
            <div>
              Correct queries percentage:{" "}
              {Math.round(
                (selectedTestStats.totalCorrectQueries /
                  selectedTestStats.totalQueries) *
                  100,
              )}
              %
            </div>
            <div>
              Average leaks (regular dataset):{" "}
              {selectedTestStats.averageLeaksMb !== null
                ? Math.round(selectedTestStats.averageLeaksMb) + "MB"
                : "-"}
            </div>
            <div>
              Average execution time:{" "}
              {selectedTestStats.averageTimeS !== null
                ? Math.round(selectedTestStats.averageTimeS) + "s"
                : "-"}
            </div>
            <div>
              Average memory usage:{" "}
              {selectedTestStats.averageMemMb !== null
                ? Math.round(selectedTestStats.averageMemMb) + "MB"
                : "-"}
            </div>
            <div>
              Average validation score:{" "}
              {selectedTestStats.averageValidationScore !== null
                ? selectedTestStats.averageValidationScore + "%"
                : "-"}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
