import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { apiUrl, titlePrefix } from "../common";
import CodeViewer from "../components/CodeViewer";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Log() {
  const [logFile, setLogFile] = useState("");
  const { id, repo } = useParams();
  const [selectedTest] = useState(id);
  const [selectedRepo] = useState(repo);
  const [loading, setLoading] = useState(false);

  const getLog = () => {
    setLoading(true);
    axios.get(apiUrl + `/run/${selectedTest}/${selectedRepo}/log`).then((x) => {
      setLogFile(x.data.log);
      setLoading(false);
    });
  };

  useEffect(() => {
    document.title = `${titlePrefix} - Test ${selectedTest} ${selectedRepo} Log`;
    getLog();
  }, []);

  return (
    <div>
      <div className="mb-10">
        <div className="text-xl">
          <Link to={`/test/${selectedTest}`} className="underline ">
            Test {selectedTest}
          </Link>
          <div className="inline">{" > "}</div>
          <div className="inline">
            Log of {selectedRepo} from Test {selectedTest}
          </div>
          <LoadingSpinner active={loading} />
        </div>
      </div>
      <CodeViewer>{logFile}</CodeViewer>
    </div>
  );
}
