import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { apiUrl, titlePrefix } from "../common";
import LoadingSpinner from "../components/LoadingSpinner";
import GroupViewer from "../components/GroupViewer";

export default function Group() {
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState([]);
  const { id, message = "" } = useParams();

  const getGroup = () => {
    setLoading(true);
    axios.get(apiUrl + `/group/${id}`).then((x) => {
      const newElements = Array.from(x.data, (e) => ({
        email: e.email,
        github: e.github,
        inviteStatus: e.inviteStatus,
      }));
      setElements(newElements);
      setLoading(false);
    });
  };

  useEffect(() => {
    document.title = `${titlePrefix} - Group ${id}`;
    getGroup();
  }, []);

  return (
    <div>
      <div className="mb-4">
        <b>Group {id}</b>
        <LoadingSpinner active={loading} />
      </div>
      <div className="text-red-500 text-lg">
        {" "}
        {message.length > 0 ? "Warning: " + message : ""}
      </div>
      <div className="mb-6 overflow-x-auto">
        <GroupViewer elements={elements} />
      </div>
    </div>
  );
}
