import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl, titlePrefix } from "../common";
import LoadingSpinner from "../components/LoadingSpinner";
import GroupsViewer from "../components/GroupsViewer";

export default function GroupsPending() {
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);

  const getPendingGroups = () => {
    setLoading(true);
    axios.get(apiUrl + `/group/pending`).then((x) => {
      const newGroups = Array.from(x.data, (e) => ({
        groupId: e.groupId,
        email: e.email,
        github: e.github,
        inviteStatus: e.inviteStatus,
      }));
      setGroups(newGroups);
      setLoading(false);
    });
  };

  useEffect(() => {
    document.title = `${titlePrefix} - Pending Groups`;
    getPendingGroups();
  }, []);

  return (
    <div>
      <div className="mb-4">
        <b>Pending Groups</b>
        <LoadingSpinner active={loading} />
      </div>
      <GroupsViewer groups={groups} />
      <div className="mb-6 overflow-x-auto"></div>
    </div>
  );
}
